<template>
  <div>

    <div class="banner">
    </div>

    <div class="zone brand">
      <div class="area-title">
        <p class="title">服务品牌</p>
      </div>
      <div class="brand-content">
        <el-carousel
          class="brand-carousel"
          ref="brandCarousel"
          height="23vw"
          indicator-position="none"
          :autoplay="true"
          arrow="always"
        >
          <el-carousel-item
            class="brand-carousel-item"
            v-for="(item, index) in brands"
            :key="index"
          >
            <div
              class="brand-carousel-item_c"
              :style="{'background-image': 'url('+item.bg+')'}"
            >

            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: "Home",
  components: {

  },
  data() {
    return {
      brands: [
        {
          bg: require('@/assets/images/home/v2/brand1.png')
        },
        {
          bg: require('@/assets/images/home/v2/brand1.png')
        },
        {
          bg: require('@/assets/images/home/v2/brand1.png')
        }
      ],
    }
  },
  computed: {

  },
  watch: {

  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
.banner {
  height: 36.2vw;
  background-image: url('../../../assets/images/strength-brand/v1/banner.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.brand ::v-deep .el-carousel__arrow {
  color: #e61d0e;
  background-color: white;
  border: 1px dotted #e61d0e;
  width: 4.4rem;
  height: 4.4rem;
  line-height: 4.4rem;
  font-size: 3.2rem;
}
.brand ::v-deep .el-icon-arrow-right {
  font-weight: bold;
}
.brand ::v-deep .el-icon-arrow-left {
  font-weight: bold;
}
.brand {
  background: white;
  margin-bottom: 4rem;
  .brand-content {
    margin-top: 9rem;
    .brand-carousel-item {
      padding: 1.5vw 0 0;
      box-sizing: border-box;
      .brand-carousel-item_c {
        width: 90vw;
        height: 22.5vw;
        margin: 0 4.68vw;
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }
  }
}
.area-title {
  text-align: center;
  .title {
    position: relative;
    font-size: 3rem;
    font-weight: bold;
    color: #000000;
    &::before {
      position: absolute;
      top: 5.4rem;
      left: 50%;
      transform: translateX(-50%);
      content: '';
      width: 10rem;
      height: 0.4rem;
      background-color: #e61d0e;
      border-radius: 0.2rem;
    }
  }
}
.zone {
  padding: 4.9rem 0 2rem;
}
</style>
